import { fetchWrapper } from '../helpers/fetch-wrapper';
import { fetchStrapiWrapper } from '../helpers/fetch-strapi-wrapper';

export const getQueryForms = () => {
  const result = fetchStrapiWrapper.get('/api/query-forms', {});
  return result;
};

export const getQueryFormOffers = () => {
  const result = fetchWrapper.get('/strapi/professional/get-query-forms', {});
  return result;
};

export const getQueryFormUserOffers = () => {
  const result = fetchWrapper.get('/strapi/get-query-forms', {});
  return result;
};

export const getUserQueryForms = uid => {
  if (uid) {
    return fetchWrapper.get(`/strapi/professional/query-forms/${uid}`, {});
  } else {
    return fetchWrapper.get('/strapi/query-forms', {});
  }
};

export const getUserQueryForm = (id, uid) => {
  if (uid) {
    return fetchWrapper.get(`/strapi/professional/query-forms/${uid}/${id}`, {});
  } else {
    return fetchWrapper.get(`/strapi/query-forms/${id}`, {});
  }
};

export const postQueryFormOffer = (uid, data) => {
  return fetchWrapper.post(`/strapi/professional/query-form-offer/${uid}`, data);
};

export const postQueryFormUserOffer = data => {
  return fetchWrapper.post(`/strapi/query-form-offer`, data);
};

export const getQueryForm = id => {
  const result = fetchStrapiWrapper.get(`/api/query-forms/${id}?populate=deep`, {});
  return result;
};

export const postQueryForm = (id, data) => {
  const result = fetchWrapper.post(`/strapi/query-forms/${id}`, data);
  return result;
};

export const getLabForms = () => {
  const result = fetchStrapiWrapper.get('/api/lab-forms', {});
  return result;
};

export const getLabFormOffers = () => {
  const result = fetchWrapper.get('/strapi/professional/get-lab-forms', {});
  return result;
};

export const getLabFormUserOffers = () => {
  const result = fetchWrapper.get('/strapi/get-lab-forms', {});
  return result;
};

export const getUserLabForms = uid => {
  if (uid) {
    return fetchWrapper.get(`/strapi/professional/lab-forms/${uid}`, {});
  } else {
    return fetchWrapper.get('/strapi/lab-forms', {});
  }
};

export const getUserLabForm = (id, uid) => {
  if (uid) {
    return fetchWrapper.get(`/strapi/professional/lab-forms/${uid}/${id}`, {});
  } else {
    return fetchWrapper.get(`/strapi/lab-forms/${id}`, {});
  }
};

export const postLabFormOffer = (uid, data) => {
  return fetchWrapper.post(`/strapi/professional/lab-form-offer/${uid}`, data);
};

export const postLabFormUserOffer = data => {
  return fetchWrapper.post(`/strapi/lab-form-offer`, data);
};

export const getLabForm = id => {
  const result = fetchStrapiWrapper.get(`/api/lab-forms/${id}?populate=deep`, {});
  return result;
};

export const postLabForm = (id, data) => {
  const result = fetchWrapper.post(`/strapi/lab-forms/${id}`, data);
  return result;
};
