import React, { lazy } from 'react';
import { PrivateRoute } from './components';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

const BackendError = lazy(() => import('./pages/Errors/BackendError'));
const NotFound = lazy(() => import('./pages/Errors/NotFound'));
const PasswordReset = lazy(() => import('./pages/Authentication/PasswordReset'));
const NewPassword = lazy(() => import('./pages/Authentication/NewPassword'));
const ConfirmEmail = lazy(() => import('./pages/Authentication/ConfirmEmail'));
const Signin = lazy(() => import('./pages/Authentication/Signin'));
const Signup = lazy(() => import('./pages/Authentication/Signup'));
const SignedUp = lazy(() => import('./pages/Authentication/SignedUp'));
const ProfessionalSignup = lazy(() => import('./pages/Authentication/ProfessionalSignup'));

const Dashboard = lazy(() => import('./containers/Dashboard'));
const SubscriptionCancel = lazy(() => import('./components/Profile/SubscriptionCancel'));
const ProfileCancel = lazy(() => import('./components/Profile/ProfileCancel'));

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const { scope } = useSelector(x => x.auth);
  const scopeShort = scope?.toLowerCase().replace(/perm$/, '');

  const dataRouter = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/404' element={<NotFound />} />
        <Route path='/500' element={<BackendError />} />
        <Route path='/forgot' element={<PasswordReset />} />
        <Route path='/confirm/:hash' element={<ConfirmEmail />} />
        <Route path='/new-password/:hash' element={<NewPassword />} />
        <Route path='/profile-cancel/:hash' element={<NewPassword />} />
        <Route path='/subscription-cancel/:id/:hash' element={<SubscriptionCancel />} />
        <Route path='/profile-cancel/:id/:hash' element={<ProfileCancel />} />
        <Route path='/signin' element={<Signin />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signed-up' element={<SignedUp />} />
        <Route path='/professional-signup' element={<ProfessionalSignup />} />
        <Route
          path='*'
          element={
            <PrivateRoute public={!scopeShort ? true : false}>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </>,
    ),
  );

  return (
    <div className='app-container'>
      <div
        id='g_id_onload'
        data-client_id='177472409440-bmjhf4mpia6rurio4p65kk12uvit4a26.apps.googleusercontent.com'
        data-auto_prompt='false'
      ></div>
      <div id='fb-root'></div>
      <script
        async
        defer
        crossOrigin='anonymous'
        src='https://connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v19.0&appId=702153235297088'
        nonce='v1ZhII1J'
      ></script>
      <div className='container'>
        <RouterProvider router={dataRouter} />
      </div>
    </div>
  );
};
export default App;
