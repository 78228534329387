import AppProvider from './components/AppProvider/AppProvider';
import React, { Suspense } from 'react';
import registerServiceWorker from './registerServiceWorker';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import LazyFallback from './lazy-fallback';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <AppProvider>
      <Suspense fallback={<LazyFallback />}>
        <App />
      </Suspense>
    </AppProvider>
  </Provider>,
);

registerServiceWorker();
