import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute(props) {
  const isPublic = props.public ?? false;
  const { user: authUser } = useSelector(x => x.auth);

  if (!isPublic && !authUser) {
    // const navigate = useNavigate();
    // not logged in so redirect to login page with the return url
    // navigate('/signin', { replace: true, state: { from: history.location } });
    // return <></>;
    return <Navigate to='/signin' />;
  }

  // authorized so return child components
  return props.children;
}

export default PrivateRoute;
