import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseCondition } from './helper';
import { makeStyles } from '@mui/styles';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginBottom: 16,
  },
  group: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
}));
const FormCheckFieldForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      if (CheckFieldType === 'checkbox') {
        return handleCheckChange(-1);
      } else if (CheckFieldType === 'radio') {
        return handleRadioChange(null);
      }
      return '';
    },
    elem() {
      return refElem.current;
    },
  }));

  const { attr, field, values, onChange, value, disabled } = props;
  const classes = useStyles();
  const CheckFieldType = field.Type ?? 'checkbox';
  const isRequired = field.isRequired;
  const requiredRegexp = field.RequiredRegexp;
  const condition = field.Condition ?? '';
  const description = field.Description ?? '';

  const refCheckboxes = useRef([]);
  const [radioValue, setRadioValue] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [checkValues, setCheckValues] = useState([]);
  const refElem = useRef(null);

  useEffect(() => {
    if (CheckFieldType === 'checkbox') {
      if (typeof value === 'string') {
        setCheckValues((value ?? '').split(','));
      } else if (value !== undefined && value?.length) {
        setCheckValues(value);
      }
    } else {
      setRadioValue(value);
    }
  }, [CheckFieldType, value]);

  const optionsData = field.CheckOptions?.checkOptions?.data ?? [];
  const options = [];
  for (const optionData of optionsData) {
    options.push({
      id: optionData.id,
      option: optionData.attributes?.Option,
    });
  }

  const handleRadioChange = value => {
    if (value !== null) {
      setRadioValue(value);
    } else {
      value = radioValue;
    }
    let errMsg = '';
    if (isRequired && !value) {
      errMsg = 'Kötelező mező!';
    } else if (requiredRegexp && !value.match(new RegExp(requiredRegexp))) {
      errMsg = 'Nem megfelelő érték!';
    }
    setErrorMsg(errMsg);
    onChange(attr, value);
    return errMsg;
  };

  const handleCheckChange = (index, option, checked) => {
    let currentValues = JSON.parse(JSON.stringify(checkValues));
    if (index !== -1) {
      if (checked && !currentValues.includes(option)) {
        currentValues.push(option);
      } else if (!checked && checkValues.includes(option)) {
        currentValues = currentValues.filter(item => item !== option);
      }
    }
    let errMsg = '';
    if (isRequired && !currentValues.length) {
      errMsg = 'Kötelező mező!';
    } else if (
      requiredRegexp &&
      !currentValues.find(item => item.match(new RegExp(requiredRegexp)))
    ) {
      errMsg = 'Nem megfelelő érték!';
    }
    setErrorMsg(errMsg);
    setCheckValues(currentValues);
    onChange(attr, currentValues);
    return errMsg;
  };

  let input = <></>;
  if (parseCondition(condition, values)) {
    if (CheckFieldType === 'checkbox') {
      input = (
        <FormControl
          component='fieldset'
          className={[classes.formControl, classes.group].join(' ')}
          required={isRequired}
          error={errorMsg ? true : false}
          disabled={disabled}
          ref={refElem}
        >
          <FormLabel component='legend'>{field.Name}</FormLabel>
          {options.map((option, index) => (
            <FormControlLabel
              key={`option-${index}`}
              control={
                <Checkbox
                  checked={
                    (values[attr]?.length > 0 && values[attr].includes(option.option)) ||
                    (typeof values[attr] === 'string' &&
                      `,${values[attr]},`.indexOf(`,${option.option},`) !== -1)
                  }
                  onChange={event => handleCheckChange(index, option.option, event.target.checked)}
                  ref={el => (refCheckboxes.current[index] = el)}
                  name={`${attr}[${index}]`}
                  color='primary'
                />
              }
              label={option.option}
            />
          ))}
          {description && <FormHelperText>{description}</FormHelperText>}
          {errorMsg && <FormHelperText error={errorMsg ? true : false}>{errorMsg}</FormHelperText>}
        </FormControl>
      );
    } else if (CheckFieldType === 'radio') {
      input = (
        <FormControl
          component='fieldset'
          className={[classes.formControl, classes.group].join(' ')}
          required={isRequired}
          error={errorMsg ? true : false}
          disabled={disabled}
          ref={refElem}
        >
          <FormLabel component='legend'>{field.Name}</FormLabel>
          <RadioGroup
            aria-label={field.Name}
            name={attr}
            value={values[attr] ?? false}
            onChange={event => handleRadioChange(event.target.value)}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={`option-${index}`}
                value={option.option}
                control={<Radio />}
                label={option.option}
              />
            ))}
          </RadioGroup>
          {errorMsg && <FormHelperText error={errorMsg ? true : false}>{errorMsg}</FormHelperText>}
        </FormControl>
      );
    }
  }
  return input;
};
const FormCheckField = forwardRef(FormCheckFieldForwardRef);

FormCheckField.propTypes = {
  attr: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
};

export default FormCheckField;
