import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { notificationCenterWidth } from '../../styleVariables';
import { delNotification, delNotifications, getNotifications } from 'services/auth.service';
import DeleteIcon from '@mui/icons-material/Delete';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { hu } from 'date-fns/locale';
import { subHours } from 'date-fns';
import { blue, green, grey, orange, red, teal } from '@mui/material/colors';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: notificationCenterWidth,
    maxWidth: notificationCenterWidth,
    [theme.breakpoints.down('sm')]: {
      top: '56px!important',
      height: 'calc(100vh - 56px)',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px!important',
      height: 'calc(100vh - 64px)',
    },
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      top: '56px!important',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px!important',
    },
    zIndex: theme.zIndex.drawer + 99,
  },
  backdrop: {
    [theme.breakpoints.down('sm')]: {
      top: '56px!important',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px!important',
    },
  },
  container: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1,
    flexGrow: 1,
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: '50%',
    color: theme.palette.text.primary,
  },
  padding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },

  marginRight: {
    marginRight: 12,
  },
  fontSizePrimary: {
    fontSize: 12,
  },
  fontSizeSecondary: {
    fontSize: 10,
    fontStyle: 'italic',
    color: theme.palette.text.hint,
  },
  notification: {
    position: 'relative',
  },
  unread: {
    position: 'absolute',
    left: 0,
    top: 12,
    zIndex: 100,
  },
  primaryMain: {
    color: teal[500],
    backgroundColor: 'transparent',
  },
  secondaryMain: {
    color: grey[500],
    backgroundColor: 'transparent',
  },
  errorMain: {
    color: red[500],
    backgroundColor: 'transparent',
  },
  warningMain: {
    color: orange[500],
    backgroundColor: 'transparent',
  },
  successMain: {
    color: green[500],
    backgroundColor: 'transparent',
  },
  infoMain: {
    color: blue[500],
    backgroundColor: 'transparent',
  },
  textPrimary: {
    color: grey[900],
    backgroundColor: 'transparent',
  },

  primaryMainAvatar: {
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: teal[500],
  },
  secondaryMainAvatar: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
  },
  errorMainAvatar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  warningMainAvatar: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
  },
  successMainAvatar: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  infoMainAvatar: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  textPrimaryAvatar: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
  },
}));

Notification.propTypes = {
  noti: PropTypes.object.isRequired,
  deleted: PropTypes.func.isRequired,
};
function Notification(props) {
  const { noti, deleted } = props;
  const classes = useStyles();

  let avatarIcon = null;
  if (noti.type === 'warning') {
    avatarIcon = <WarningIcon />;
  } else if (noti.type === 'error') {
    avatarIcon = <ErrorIcon />;
  } else if (noti.type === 'success') {
    avatarIcon = <CheckCircleIcon />;
  } else if (noti.type === 'info') {
    avatarIcon = <InfoIcon />;
  }
  const time = formatDistanceToNow(subHours(new Date(noti.date), 2), { locale: hu });

  const handleDelete = () => {
    delNotification(noti.id).then(response => {
      if (response.data) {
        deleted(noti.id);
      }
    });
  };

  return (
    <ListItemButton button={!!noti.link} className={classes.notification}>
      {!noti.isRead && <FiberNewIcon className={classes.unread} />}
      {avatarIcon && (
        <Avatar className={[classes.marginRight, classes[`${noti.color}Avatar`]].join(' ')}>
          {avatarIcon}
        </Avatar>
      )}
      <ListItemText
        primary={
          noti.link ? (
            <Typography
              variant='subtitle2'
              className={[classes.fontSizePrimary, classes[noti.color]].join(' ')}
            >
              <Link to={noti.link.replace(process.env.REACT_APP_PUBLIC_URL, '/')}>
                {noti.message}
              </Link>
            </Typography>
          ) : (
            <Typography
              variant='subtitle2'
              className={[classes.fontSizePrimary, classes[noti.color]].join(' ')}
            >
              {noti.message}
            </Typography>
          )
        }
        secondary={
          noti.isRead ? (
            <Typography className={classes.fontSizeSecondary} variant='subtitle2'>
              {time}
            </Typography>
          ) : (
            <Typography className={classes.fontSizeSecondary} variant='body2'>
              {time}
            </Typography>
          )
        }
      />
      <ListItemSecondaryAction>
        <IconButton size='small' onClick={handleDelete} edge='end' aria-label='comments'>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}

const NotificationCenter = ({ notificationsOpen, toggleNotifications }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [notifications, setNotifications] = useState([]);

  const handleTabToggle = (event, tab) => setTab(tab);

  const passNotificationsOpen = notificationsOpen;
  const passtoogleNotifications = toggleNotifications;
  const handleClose = (open, toggle, event, reason) => {
    if (reason === 'backdropClick') {
      toggle();
      return;
    }

    if (reason === 'escapeKeyDown') {
      toggle();
      return;
    }
    return true;
  };

  const handleDeleteNotification = id => {
    const newNotifications = notifications.filter(item => item.id !== id);
    setNotifications(newNotifications);
  };

  const handleDeleteAllNotification = () => {
    delNotifications().then(response => {
      if (response.data) {
        setNotifications([]);
        toggleNotifications();
      }
    });
  };

  useEffect(() => {
    if (notificationsOpen) {
      getNotifications().then(response => {
        console.log('getNotifications', response);
        const newNotifications = [];
        for (const noti of response.data) {
          const color = ['primary', 'secondary', 'error', 'warning', 'info', 'success'].includes(
            noti.type,
          )
            ? `${noti.type}Main`
            : 'textPrimary';
          noti.color = color;
          newNotifications.push(noti);
        }
        console.log('newNotifications', newNotifications);
        setNotifications(newNotifications);
      });
    }
  }, [notificationsOpen]);

  return (
    <Drawer
      variant='temporary'
      anchor='right'
      open={notificationsOpen}
      ModalProps={{
        keepMounted: false,
        className: classes.modal,
        BackdropProps: {
          className: classes.backdrop,
        },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={(event, reason) =>
        handleClose(passNotificationsOpen, passtoogleNotifications, event, reason)
      }
    >
      {/* <Tabs
        value={tab}
        onChange={handleTabToggle}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        centered
      >
        <Tab classes={{ root: classes.tabRoot }} label='Today' />
        <Tab classes={{ root: classes.tabRoot }} label='Notifications' />
      </Tabs>
      <div className={classes.container}> */}
      {/* tab === 0 && (
        <>
          <div className={classes.padding}>
            <Typography variant='h6' gutterBottom>
              {format(today, 'dddd')}
            </Typography>
            <Typography variant='subtitle1'>{format(today, 'MMM Do yy')}</Typography>
          </div>
          {stocks && (
            <>
              <Divider />
              <List component='nav' subheader={<ListSubheader disableSticky>Stocks</ListSubheader>}>
                {stocks['Stock Quotes'].map((stock, index) => (
                  <ListItem button key={index}>
                    <ListItemText primary={stock['1. symbol']} />
                    <ListItemSecondaryAction className='mx-1'>
                      <Typography variant='caption'>{formatPrice(stock['2. price'])}</Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {forecast && (
            <>
              <Divider />
              <List subheader={<ListSubheader disableSticky>Weather</ListSubheader>}>
                <ListItem button>
                  <ListItemText primary={forecast.city.country} secondary={forecast.city.name} />
                  <ListItemSecondaryAction className='mx-1'>
                    <Typography variant='h6'>
                      {
                        <>
                          <i
                            className={classNames(
                              getWeatherIcon(forecast.list[0].weather[0].icon),
                              'text-lg mx-1',
                            )}
                          />
                          {forecast.list[0].main.temp}
                        </>
                      }
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </>
          )}
          <Divider />
          <List subheader={<ListSubheader disableSticky>Tasks</ListSubheader>}>
            {mockTodo.map((todo, index) => (
              <ListItem button key={index}>
                <ListItemText primary={todo.title} secondary={todo.subtitle} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List subheader={<ListSubheader disableSticky>Stats</ListSubheader>}>
            {mockStats.map((stat, index) => (
              <div className={classes.padding} key={index}>
                <Typography variant='caption'>{stat.title}</Typography>
                <LinearProgress variant='determinate' value={stat.value} />
              </div>
            ))}
          </List>
        </>
      ) */}
      {tab === 1 && (
        <>
          <Grid container justifyContent='flex-end' className='pt-2'>
            <Grid item>
              <Button color='secondary' size='small' onClick={handleDeleteAllNotification}>
                Összes törlése
              </Button>
            </Grid>
          </Grid>
          <List>
            {notifications.map((notification, index) => (
              <Notification
                key={index}
                noti={notification}
                deleted={handleDeleteNotification}
              ></Notification>
            ))}
          </List>
        </>
      )}
      {/* </div> */}
    </Drawer>
  );
};

NotificationCenter.propTypes = {
  notificationsOpen: PropTypes.bool,
  toggleNotifications: PropTypes.func,
};

export default NotificationCenter;
