import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseCondition } from './helper';
import { makeStyles } from '@mui/styles';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { hu } from 'date-fns/locale';
// import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import moment from 'moment';
import { parseISO } from 'date-fns';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginBottom: 16,
  },
}));

const FormDateFieldForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      return handleChange(null);
    },
    elem() {
      return refElem.current;
    },
  }));

  const { attr, field, values, onChange, value, disabled } = props;

  const classes = useStyles();

  const isRequired = field.isRequired;
  const requiredRegexp = field.RequiredRegexp;
  const condition = field.Condition ?? '';
  const description = field.Description ?? '';

  const [inputValue, setInputValue] = useState(new Date());
  const [errorMsg, setErrorMsg] = useState('');

  const refElem = useRef(null);

  useEffect(() => {
    setInputValue(value ? (typeof value === 'string' ? parseISO(value) : value) : new Date());
  }, [value]);

  const handleChange = value => {
    if (value !== null) {
      setInputValue(typeof value === 'string' ? parseISO(value) : value);
    } else {
      value = inputValue;
    }
    let errMsg = '';
    if (isRequired && !value) {
      errMsg = 'Kötelező mező!';
    } else if (requiredRegexp && !value.match(new RegExp(requiredRegexp))) {
      errMsg = 'Nem megfelelő érték!';
    }
    setErrorMsg(errMsg);
    onChange(attr, value);
    return errMsg;
  };

  if (parseCondition(condition, values)) {
    return (
      <FormControl
        component='fieldset'
        error={errorMsg ? true : false}
        className={classes.formControl}
        disabled={disabled}
        ref={refElem}
      >
        <FormLabel component='legend'>{field.Name}</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
          <DatePicker
            disableToolbar
            autoOk
            className={classes.width100}
            variant='inline'
            inputVariant='outlined'
            format='yyyy-MM-dd'
            margin='dense'
            name={attr}
            required={isRequired}
            value={inputValue}
            onChange={value => handleChange(value)}
            disabled={disabled}
            renderInput={props => <TextField helperText={errorMsg} />}
          />
        </LocalizationProvider>
        {description && <FormHelperText>{description}</FormHelperText>}
        {errorMsg && <FormHelperText error={errorMsg ? true : false}>{errorMsg}</FormHelperText>}
      </FormControl>
    );
  }
  return <></>;
};
const FormDateField = forwardRef(FormDateFieldForwardRef);
FormDateField.propTypes = {
  attr: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
};
export default FormDateField;
