import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
  },
  img: {
    opacity: 0.4,
    width: '100px',
  },
}));
function LazyFallback(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.container} justifyContent={'center'} alignItems={'center'}>
      <Grid item>
        <img className={`loading-spinner ${classes.img}`} alt='loading' src='/spinner.gif' />
      </Grid>
    </Grid>
  );
}

export default LazyFallback;
