import slugify from 'slugify';

export function parseCondition(conditionArg, values) {
  let conditions = [conditionArg];
  let isOk = [true];
  let logical = 'or';
  if (conditionArg.match(/([\s]*\|\|[\s]*)/g)) {
    const arr = conditionArg.split('||');
    conditions = arr.map(item => item.trim());
    isOk = JSON.parse(JSON.stringify(conditions)).fill(true);
  } else if (conditionArg.match(/([\s]*&&[\s]*)/g)) {
    logical = 'and';
    const arr = conditionArg.split('&&');
    conditions = arr.map(item => item.trim());
    isOk = JSON.parse(JSON.stringify(conditions)).fill(true);
  }
  for (const [index, condition] of conditions.entries()) {
    const conditionMatch =
      condition.indexOf('<>') !== -1
        ? condition.match(/^([^=]+)(<>?)(.*)$/)
        : condition.indexOf('!=') !== -1
          ? condition.match(/^([^=]+)(!=?)(.*)$/)
          : condition.indexOf('=') !== -1
            ? condition.match(/^([^=]+)(=?)(.*)$/)
            : condition.match(/^(.+)$/);
    if (conditionMatch) {
      const attr = slugify(conditionMatch[1], { lower: true, remove: /[*+~.,\[\](){}'"!?:@]/g });
      if ((conditionMatch[2] === '!=' || conditionMatch[2] === '<>') && conditionMatch[3]) {
        if (Array.isArray(values[attr]) && values[attr].includes(conditionMatch[3])) {
          isOk[index] = false;
        } else if (!Array.isArray(values[attr]) && values[attr] === conditionMatch[3]) {
          isOk[index] = false;
        }
      } else if (conditionMatch[2] === '=' && conditionMatch[3]) {
        if (Array.isArray(values[attr]) && !values[attr].includes(conditionMatch[3])) {
          isOk[index] = false;
        } else if (!Array.isArray(values[attr]) && values[attr] !== conditionMatch[3]) {
          isOk[index] = false;
        }
      } else {
        if (Array.isArray(values[attr]) && values[attr].length) {
          isOk[index] = false;
        } else if (!Array.isArray(values[attr]) && !values[attr]) {
          isOk[index] = false;
        }
      }
    }
  }
  if (logical === 'or') {
    return isOk.find(item => item === true);
  } else if (logical === 'and') {
    return isOk.filter(item => item !== true).length === 0;
  }
}
