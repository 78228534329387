import React, { createContext, useContext, useEffect, useReducer } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
// import { StylesProvider, jssPreset, ThemeProvider } from '@material-ui/styles';
// import { create } from 'jss';
import { blueGrey, grey, red } from '@mui/material/colors';
// import rtl from 'jss-rtl';
import { getContrastRatio, useMediaQuery } from '@mui/material';
// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Context = createContext();
const { Provider } = Context;

const reducer = (state, action) => {
  switch (action.type) {
    case 'direction': {
      const newState = {
        ...state,
        direction: state.direction === 'ltr' ? 'rtl' : 'ltr',
      };
      return newState;
    }
    case 'type': {
      return { ...state, type: state.type === 'light' ? 'dark' : 'light' };
    }
    default: {
      return state;
    }
  }
};

const AppProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('@media (prefers-color-scheme: dark)');
  const [state, dispatch] = useReducer(reducer, {
    mode: prefersDarkMode ? 'dark' : 'light',
    direction: 'ltr',
  });

  const theme = createTheme(
    adaptV4Theme({
      direction: state.direction,
      palette: {
        mode: state.type,
        primary: {
          //teal,
          background: '#32a888',
          light: '#A3FFE5',
          main: '#3db391',
          dark: '#2B7F68',
          contrastText: '#fff',
        },
        default: {
          background: '#f8f7f7',
          light: grey[100],
          main: grey[300],
          dark: grey[500],
          darker: grey[900],
          contrastText: getContrastRatio(grey[300], '#fff') > 4.5 ? '#fff' : '#111',
        },
        secondary: { ...blueGrey },
        error: {
          background: red[800],
          light: red[100],
          main: red[300],
          dark: red[500],
          darker: red[900],
          contrastText: getContrastRatio(red[300], '#fff') > 4.5 ? '#fff' : '#111',
        },
      },
      typography: {
        headline: {
          fontSize: '1rem',
        },
        subtitle1: {
          fontSize: '0.8125rem',
        },
        button: {
          fontWeight: 400,
          textTransform: 'initial',
        },
        body1: {
          fontSize: '0.875rem',
        },
      },
      shape: {
        borderRadius: 4,
      },
      zIndex: {
        drawer: 100,
      },
    }),
  );

  useEffect(() => {
    document.body.dir = state.direction;
  }, [state.direction]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/*<StylesProvider jss={jss}>*/}
        <Provider value={[state, dispatch]}>{children}</Provider>
        {/*</StylesProvider>*/}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppProvider;
export const useAppState = () => useContext(Context);
