import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { getLabForm, getQueryForm, postLabForm, postQueryForm } from '../../services/form.service';
import FormFieldCategory from './Components/FormFieldCategory';
import { useForm } from 'react-hook-form';
import slugify from 'slugify';
import FormField from './Components/FormField';
import FormFieldDescription from './Components/FormFieldDescription';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
} from '@mui/material';
import FormCheckField from './Components/FormCheckField';
import { parseCondition } from './Components/helper';
import FormSelectField from './Components/FormSelectField';
import FormDateField from './Components/FormDateField';
import FormFileField from './Components/FormFileField';
import { useBlocker, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  padding: {
    padding: 16,
  },
  form: {
    maxWidth: 500,
  },
}));

function FormBuilder(props) {
  const { type, caller, id, formValues, status, searched } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const [fields, setFields] = useState([]);
  const [values, setValues] = useState({});
  const [elements, setElements] = useState([]);
  const [changed, setChanged] = useState(false);

  const refIsDirty = useRef(false);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      refIsDirty.current && currentLocation.pathname !== nextLocation.pathname,
  );

  window.addEventListener('beforeunload', ev => {
    if (refIsDirty.current) {
      ev.preventDefault();
      ev.returnValue = '';
    }
  });

  const refs = useRef([]);

  const [snackbarColor, setSnackbarColor] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarColor('warning');
    setSnackbarMessage('');
  };

  const {
    register,
    // handleSubmit,
    setValue,
    getValues,
    // watch,
    formState: { errors },
  } = useForm({});

  const handleChange = (attr, value) => {
    refIsDirty.current = true;
    setValue(attr, value);
    const values = getValues();
    setValues(values);
    setChanged(true);
  };

  const handleFormSubmit = () => {
    let values = getValues();
    let hasError = false;
    for (const [index, element] of elements.entries()) {
      const field = fields[index] ?? null;
      if (parseCondition(field?.Condition ?? '', values)) {
        if (refs?.current?.length > 0 && refs.current[index]?.triggerChange) {
          const refError = refs.current[index].triggerChange();
          if (refError) {
            hasError = true;
          }
        }
      } else if (field?.attr && values[field.attr]) {
        delete values[field.attr];
      }
    }
    values = getValues();
    if (!hasError && type === 'query') {
      postQueryForm(id, values)
        .then(result => {
          if (result.data?.success) {
            refIsDirty.current = false;
            if (caller) {
              if (caller === 'reload') {
                window.location.reload();
              } else {
                navigate(caller);
              }
            } else {
              setSnackbarColor('success');
              setSnackbarMessage('Az űrlap sikeresen elmentve!');
            }
          } else {
            console.log('error', result.data);
            const errors = [];
            for (const err of result.data.errors) {
              if (err.error && err.field) {
                errors.push(`Hibás mező: ${err.field} (${err.error})`);
              } else {
                errors.push(`Hiba történt! (${err.error})`);
              }
              setSnackbarColor('error');
              setSnackbarMessage(errors.join(', '));
            }
          }
        })
        .catch(err => console.error(err));
    } else if (!hasError && type === 'lab') {
      postLabForm(id, values)
        .then(result => {
          if (result.data?.success) {
            refIsDirty.current = false;
            if (caller) {
              if (caller === 'reload') {
                window.location.reload();
              } else {
                navigate(caller);
              }
            } else {
              setSnackbarColor('success');
              setSnackbarMessage('A labor sikeresen elmentve!');
            }
          } else {
            console.log('error', result.data);
            const errors = [];
            for (const err of result.data.errors) {
              if (err.error && err.field) {
                errors.push(`Hibás mező: ${err.field} (${err.error})`);
              } else {
                errors.push(`Hiba történt! (${err.error})`);
              }
              setSnackbarColor('error');
              setSnackbarMessage(errors.join(', '));
            }
          }
        })
        .catch(err => console.error(err));
    }
    setChanged(false);
  };

  useEffect(() => {
    const fn = type === 'lab' ? getLabForm : getQueryForm;
    fn(id)
      .then(result => {
        if (result?.data?.data?.attributes?.Fields) {
          if (caller.indexOf('/professionals/') !== -1 || caller.indexOf('/admin/') !== -1) {
            for (const field of result.data.data.attributes.Fields) {
              field.Condition = '';
            }
          }
          setFields(result.data.data.attributes.Fields);
        }
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (searched) {
      let foundIndex = null;
      for (const [index, field] of fields.entries()) {
        if (!foundIndex && field.Name && field.Name.match(new RegExp(searched, 'i'))) {
          foundIndex = index;
        }
      }
      if (foundIndex && refs.current[foundIndex]) {
        refs.current[foundIndex].elem().scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [searched]);

  useEffect(() => {
    if (Object.keys(formValues).length) {
      setValues(formValues);
      for (const formField in formValues) {
        const formValue = formValues[formField];
        setValue(formField, formValue);
      }
    }
  }, [formValues]);

  useEffect(() => {
    const elements = [];
    for (const [index, field] of fields.entries()) {
      const componentName = field.__component;
      const attr = slugify(field.Name ?? '', { lower: true, remove: /[*+~.,\[\](){}'"!?:@]/g });
      field.attr = attr;
      let element = <div key={`key-${index}`} ref={el => (refs.current[index] = el)}></div>;
      switch (componentName) {
        case 'form.field-category':
          element = (
            <FormFieldCategory
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              field={field}
              values={values}
            />
          );
          break;
        case 'form.field-description':
          element = (
            <FormFieldDescription
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              field={field}
              values={values}
            />
          );
          break;
        case 'form.field':
          element = (
            <FormField
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              attr={attr}
              field={field}
              values={values}
              value={values[attr]}
              onChange={handleChange}
              disabled={status === 'read'}
            />
          );
          break;
        case 'form.check-field':
          element = (
            <FormCheckField
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              attr={attr}
              field={field}
              values={values}
              value={values[attr]}
              onChange={handleChange}
              disabled={status === 'read'}
            />
          );
          break;
        case 'form.select-field':
          element = (
            <FormSelectField
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              attr={attr}
              field={field}
              values={values}
              value={values[attr]}
              onChange={handleChange}
              disabled={status === 'read'}
            />
          );
          break;
        case 'form.date-field':
          element = (
            <FormDateField
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              attr={attr}
              field={field}
              values={values}
              value={values[attr]}
              onChange={handleChange}
              disabled={status === 'read'}
            />
          );
          break;
        case 'form.file-field':
          element = (
            <FormFileField
              key={`key-${index}`}
              ref={el => (refs.current[index] = el)}
              attr={attr}
              field={field}
              values={values}
              value={values[attr]}
              onChange={handleChange}
              disabled={status === 'read'}
            />
          );
          break;
      }
      elements.push(element);
    }
    setElements(elements);
  }, [fields, values]);

  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item className={classes.padding}>
          <Paper className={classes.padding}>
            <form className={'mfp-form-builder'} autoComplete='off'>
              {elements}
              {status === 'write' && (
                <Button
                  onClick={handleFormSubmit}
                  color='primary'
                  variant='contained'
                  disabled={!changed}
                >
                  Mentés
                </Button>
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarMessage !== ''}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarColor ?? 'warning'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {blocker.state === 'blocked' ? (
        <>
          <Dialog
            open={true}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Az űrlap nincs elmentve!'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Az űrlapon történt módosításokat nem mentette még el.
                <br />
                Biztosan el akarja vetni a módosításokat?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color={'error'}
                onClick={() => {
                  blocker.proceed();
                }}
              >
                Igen
              </Button>
              <Button
                onClick={() => {
                  blocker.reset();
                }}
                autoFocus
              >
                Nem, előbb elmentem őket
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
}

FormBuilder.propTypes = {
  type: PropTypes.string.isRequired,
  caller: PropTypes.string,
  id: PropTypes.number.isRequired,
  formValues: PropTypes.object,
  status: PropTypes.oneOf(['write', 'read']),
};

export default FormBuilder;
