import { Button } from "@mui/material";
import { Card } from "@mui/material";
import { CardActions } from "@mui/material";
import { CardMedia } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from "@mui/material";
import { formatPrice } from '../../helpers';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  media: {
    height: 0
  },
  actions: {
    display: 'flex'
  }
}));

const CartCard = ({ title, price, image, imageHeight }) => {
  const classes = useStyles();
  return (
    <Card>
      <Grid container spacing={0} alignItems="center" className="pa-1">
        <Grid item className="flexSpacer">
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary">
            {formatPrice(price)}
          </Button>
        </Grid>
      </Grid>
      <CardMedia
        className={classes.media}
        style={{ paddingTop: imageHeight }}
        image={image}
        title={title}
      />
      <CardActions className={classes.actions} disableSpacing>
        <div className="flexSpacer">
          <IconButton aria-label="Add to favorites">
            <FavoriteIcon />
          </IconButton>
        </div>
        <Button variant="contained" color="secondary">
          Add to cart
        </Button>
      </CardActions>
    </Card>
  );
};

CartCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired
};

export default CartCard;
