import React, { useState } from 'react';

import { Box, Drawer, List, SwipeableDrawer, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import { drawerWidth } from '../../styleVariables';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const iOS = typeof window === 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: '100%',
    zIndex: theme.zIndex.drawer,
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      top: '56px!important',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px!important',
    },
    zIndex: '1000!important',
  },
  backdrop: {
    [theme.breakpoints.down('sm')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px',
    },
  },
}));

const Sidebar = ({ opened, toggleDrawer, routes }) => {
  const location = useLocation();
  const classes = useStyles();
  const [activeRoute, setActiveRoute] = useState(undefined);
  const toggleMenu = index => setActiveRoute(activeRoute === index ? undefined : index);

  const menu = (
    <List component='div'>
      {routes.map((route, index) => {
        const isCurrentPath = location.pathname.indexOf(route.path) > -1 ? true : false;
        return (
          <SidebarItem
            key={index}
            index={index}
            route={route}
            activeRoute={activeRoute}
            toggleMenu={toggleMenu}
            currentPath={isCurrentPath}
          />
        );
      })}
    </List>
  );

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <>
      {/*<Hidden smDown>*/}
      {!isMobile && (
        <Box className={'drawerHeight'}>
          <Drawer
            variant='persistent'
            classes={{
              paper: classes.drawerPaper,
            }}
            open={opened}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onClose: toggleDrawer,
            }}
          >
            {menu}
          </Drawer>
        </Box>
      )}
      {/*</Hidden>*/}
      {/*<Hidden mdUp>*/}
      {isMobile && (
        <Box>
          <SwipeableDrawer
            variant='temporary'
            classes={{
              paper: classes.drawerPaper,
            }}
            open={opened}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            disableBackdropTransition={!iOS}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onClose: toggleDrawer,
            }}
          >
            {menu}
          </SwipeableDrawer>
        </Box>
      )}
      {/*</Hidden>*/}
    </>
  );
};

Sidebar.prototypes = {
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object,
};

export default Sidebar;
