import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { parseCondition } from './helper';
import parse from 'html-react-parser';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const FormFieldDescriptionForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    triggerChange() {
      // refInput.current.change();
    },
    elem() {
      return refElem.current;
    },
  }));
  const refElem = useRef(null);

  const { field, values } = props;
  const classes = useStyles();
  const condition = field.Condition ?? '';
  if (parseCondition(condition, values)) {
    return (
      <Typography component={'div'} variant={'subtitle1'} ref={refElem}>
        {parse(field.Description ?? '')}
      </Typography>
    );
  }
  return <></>;
};
const FormFieldDescription = forwardRef(FormFieldDescriptionForwardRef);

FormFieldDescription.propTypes = {
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default FormFieldDescription;
