// Capitalize
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Format price
export function formatPrice(number) {
  const fnumber = parseFloat(number);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(fnumber);
}

// Get wind direction
export function windDirection(degree) {
  const sectors = ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'];

  degree += 22.5;

  if (degree < 0) {
    degree = 360 - (Math.abs(degree) % 360);
  } else {
    degree = degree % 360;
  }

  const which = parseInt(degree / 45, 10);
  return sectors[which];
}

// Get weather icon class
export function getWeatherIcon(code) {
  const weatherIcons = {
    '01d': 'pe-is-w-sun-1',
    '02d': 'pe-is-w-partly-cloudy-1',
    '03d': 'pe-is-w-partly-cloudy-2',
    '04d': 'pe-is-w-mostly-cloudy-2',
    '09d': 'pe-is-w-rain-1',
    '10d': 'pe-is-w-rain-day',
    '11d': 'pe-is-w-severe-thunderstorm',
    '13d': 'pe-is-w-snow-day-2',
    '50d': 'pe-is-w-mist',
    '01n': 'pe-is-w-moon-1',
    '02n': 'pe-is-w-partly-cloudy-2',
    '03n': 'pe-is-w-partly-cloudy-2',
    '04n': 'pe-is-w-mostly-cloudy-2',
    '09n': 'pe-is-w-rain-1',
    '10n': 'pe-is-w-rain-full-moon',
    '11n': 'pe-is-w-severe-thunderstorm',
    '13n': 'pe-is-w-snow-full-moon-1',
    '50n': 'pe-is-w-mist',
  };

  return weatherIcons[code];
}

// Get stocks data
export async function getStocks(symbols) {
  return '';
  let stocks = undefined;
  try {
    const stocksCall = await fetch(
      `//www.alphavantage.co/query?function=BATCH_STOCK_QUOTES&symbols=${symbols},&apikey=${process.env.REACT_APP_STOCKS_API_KEY}`,
    )
      .then(res => {
        if (res.ok) {
          return res;
        } else {
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .catch(console.error);

    if (stocksCall !== undefined) {
      stocks = await stocksCall.json();
    }

    return stocks;
  } catch (e) {
    return '';
  }
}

// Get weather data
export async function getWeather(city, country, days) {
  return '';
  let forecast = undefined;
  try {
    const forecastCall = await fetch(
      `//api.openweathermap.org/data/2.5/forecast?q=${city},${country}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&cnt=${days}&units=metric`,
    )
      .then(res => {
        if (res.ok) {
          return res;
        } else {
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .catch(console.error);

    if (forecastCall !== undefined) {
      forecast = await forecastCall.json();
    }

    return forecast;
  } catch (e) {
    return '';
  }
}

export function getCookie(key) {
  const b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : null;
}

export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function parseDate(dateStr) {
  const date = new Date();
  if (dateStr === 'today' || dateStr === '0') {
    return date.toISOString().substring(0, 10);
  } else if (dateStr === 'yesterday') {
    date.setDate(date.getDate() - 1);
    return date.toISOString().substring(0, 10);
  } else if (dateStr === 'tomorrow') {
    date.setDate(date.getDate() + 1);
    return date.toISOString().substring(0, 10);
  } else if (dateStr.match(/^\+[\d]+$/)) {
    const daysNo = parseInt(dateStr.substring(1));
    date.setDate(date.getDate() + daysNo);
    return date.toISOString().substring(0, 10);
  } else if (dateStr.match(/^\-[\d]+$/)) {
    const daysNo = parseInt(dateStr.substring(1));
    date.setDate(date.getDate() - daysNo);
    return date.toISOString().substring(0, 10);
  } else {
    const parsed = new Date(dateStr);
    if (parsed) {
      return parsed.toISOString().substring(0, 10);
    }
  }
  return '';
}
