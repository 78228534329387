import { yellow, lightBlue } from '@mui/material/colors';

// Colors
const infoColor = lightBlue[500];
const warningColor = yellow[500];

// Spacing
const drawerWidth = 240;
const notificationCenterWidth = 300;
const MobileBreakpoint = 960;

export { infoColor, warningColor, drawerWidth, notificationCenterWidth, MobileBreakpoint };
